import { createBrowserRouter } from "react-router-dom";
import TheLayout from "../container/TheLayout";
import Home from "../page";
import HowToPlay from "../page/how-to-play/HowToPlay";
import TermAndCondition from "../page/term-and-condition/TermAndCondition";
import ContactUs from "../page/contact/ContactUs";
import PrivacyPolicy from "../page/privacy-policy/PrivacyPolicy";

export const appRoutes = createBrowserRouter([
  {
    path: "/",
    element: <TheLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/how-to-play",
        element: <HowToPlay />,
      },
      {
        path: "/term-&-condition",
        element: <TermAndCondition />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/contactUs",
        element: <ContactUs />,
      },
    ],
  },
]);
