import { AiOutlineMenu } from "react-icons/ai";
import { createStyles } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { COLORS } from "../../color";
import { IMAGES } from "../../image";

const Styles = createStyles({
  root: {
    backgroundColor: COLORS.white,
    padding: "0px 40px",
    position: "sticky",
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    zIndex: 99999,
    "@media(max-width:992px)": {
      padding: "0.4em 0px",
    },
  },
  navbar: {
    width: "95%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5em 1.2em",
    flexWrap: "wrap",

    "@media(max-width:992px)": {
      padding: "0.4em 1.2em",
      display: " block",
      position: "relative",
    },
  },
  logoImg: {
    width: 60,
    height: 60,
  },
  menu: {
    listStyle: "none",
    padding: "0px",
    margin: " 0px",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:992px)": {
      display: "block",
    },
  },
  item: {
    textDecoration: " none",
    fontWeight: 600,
    color: "#fff",
    display: "inline-block",
    marginLeft: " 3em",
    fontSize: " 0.9em",
    scrollBehavior: "smooth",
    "@media(max-width:992px)": {
      padding: "10px 0px",
      marginLeft: " 0px",
      color: "#fff",
    },
  },
  downitem: {
    color: "#fff",
  },
  menuBar: {
    display: "none",
    position: "absolute",
    right: "1em",
    top: "12px",
    "@media(max-width:992px)": {
      display: " block",
    },
  },
  menuBtn: {
    cursor: " pointer",
    backgroundColor: "transparent",
    border: " none",
  },
  menuIcon: {
    fontSize: "40px !important",
    color: "#000",
  },
  logoSection: {
    width: "80px",
    "@media(max-width:890px)": {
      width: "70px",
      textAlign: "left",
    },
  },
  itemLink: {
    display: "inline-block",
    "@media(max-width:992px)": {
      display: "block",
      padding: "8px 0px",
    },
  },
  link: {
    color: COLORS.primary,
    fontSize: 16,
    textDecoration: "none",
    padding: "0px 16px",
    fontWeight: 700,
    textTransform: "uppercase",
    "@media(max-width:483px)": {
      fontSize: 12,
    },
  },
});

const TheNavbar = () => {
  const { classes } = Styles();
  const [show, setshow] = useState(false);
  const handleClose = () => setshow(!show);
  const [width, setwidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setwidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  return (
    <>
      <div className={`${classes.root}`}>
        <div className={`${classes.navbar} `}>
          <div className={classes.logoSection}>
            <a href={"/"}>
              <img
                className={`${classes.logoImg}`}
                src={IMAGES.logo}
                alt="logo"
              />
            </a>
          </div>
          <div className={`${classes.menuBar}`}>
            <button className={classes.menuBtn} onClick={handleClose}>
              <AiOutlineMenu className={`${classes.menuIcon}`} />
            </button>
          </div>
          <div
            style={{
              display: width <= 992 ? (show ? "block " : " none") : "block",
            }}
          >
            <ul className={classes.menu}>
              <li className={classes.itemLink}>
                <Link to="/" className={classes.link} onClick={handleClose}>
                  Home
                </Link>
              </li>

              <li className={classes.itemLink}>
                <Link
                  to="/how-to-play"
                  className={classes.link}
                  onClick={handleClose}
                >
                  How To Play
                </Link>
              </li>

              <li className={classes.itemLink}>
                <Link
                  to="/term-&-condition"
                  className={classes.link}
                  onClick={handleClose}
                >
                  Term And Condition
                </Link>
              </li>

              <li className={classes.itemLink}>
                <Link
                  to="/privacy-policy"
                  className={classes.link}
                  onClick={handleClose}
                >
                  Privacy Policy
                </Link>
              </li>

              <li className={classes.itemLink}>
                <Link
                  to="/contactUs"
                  className={classes.link}
                  onClick={handleClose}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TheNavbar;
