import { Box, Grid, createStyles } from "@mantine/core";
import React from "react";
import { COLORS } from "../../color";
import { TImages } from "../../image/image";
import { IMAGES } from "../../image";

const styles = createStyles({
  heading: {
    fontSize: "clamp(16px, 3.5vw, 38px)",
    color: COLORS.primary,
    fontWeight: 600,
    margin: 0,
  },
  description: {
    fontSize: "clamp(12px, 2.8vw, 20px)",
    fontWeight: 400,
    color: COLORS.black,
    lineHeight: "160%",
    opacity: 0.8,
  },
  marginTop: {
    marginTop: 140,
    "@media(max-width:767px)": {
      marginTop: 80,
    },
    "@media(max-width:500px)": {
      marginTop: 60,
    },
  },
  link: {
    color: COLORS.primary,
    textDecoration: "none",
  },
});

interface IGridContainer {
  firstImage: TImages;
  secondImage: TImages;
  title: string;
  paragraph: string;
  firstOrder?: number;
  secondOrder?: number;
  linkTitle?: string;
  link?: string;
}

const GridContainer = (props: IGridContainer) => {
  const {
    firstImage,
    secondImage,
    paragraph,
    title,
    firstOrder = 2,
    secondOrder = 1,
    link,
    linkTitle,
  } = props;
  const { classes } = styles();
  return (
    <Box className={classes.marginTop}>
      <Grid justify="center" align="center">
        <Grid.Col
          span={12}
          sm={6}
          xs={12}
          md={6}
          lg={6}
          xl={6}
          orderXs={firstOrder}
          orderSm={secondOrder}
          order={firstOrder}
        >
          <Grid justify="center" align="center">
            <Grid.Col span={6}>
              <Box style={{ textAlign: "center" }}>
                <img src={IMAGES[firstImage]} alt="" width={"70%"} />
              </Box>
            </Grid.Col>
            <Grid.Col span={6}>
              <Box style={{ textAlign: "left" }}>
                <img src={IMAGES[secondImage]} alt="" width={"70%"} />
              </Box>
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col
          span={12}
          sm={6}
          xs={12}
          md={6}
          lg={6}
          xl={6}
          orderXs={secondOrder}
          orderSm={firstOrder}
          order={secondOrder}
        >
          <Box>
            <h2 className={classes.heading} style={{ paddingRight: 22 }}>
              {title}
            </h2>
            <p className={classes.description}>
              {paragraph}{" "}
              <a href={link} className={classes.link}>
                {linkTitle}
              </a>
            </p>
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default GridContainer;
