import React from "react";
import { createStyles, Box, Grid } from "@mantine/core";
import { COLORS } from "../../color";

const styles = createStyles({
  root: {
    marginTop: 140,
    "@media(max-width:767px)": {
      marginTop: 80,
    },
    "@media(max-width:500px)": {
      marginTop: 60,
    },
  },

  heading: {
    fontSize: "clamp(16px, 3.5vw, 38px)",
    color: COLORS.primary,
    fontWeight: 600,
    margin: 0,
    textAlign: "center",
    paddingBottom: 8,
  },
  description: {
    fontSize: "clamp(12px, 2.8vw, 20px)",
    fontWeight: 400,
    color: COLORS.black,
    lineHeight: "160%",
    opacity: 0.8,
  },
  playStoreLink: {
    padding: "10px 30px",
    background: COLORS.primary,
    color: COLORS.white,
    fontWeight: 500,
    textDecoration: "none",
    borderRadius: 50,
  },
  downloadLink: {
    padding: "10px 30px",
    background: COLORS.white,
    color: COLORS.primary,
    fontWeight: 500,
    textDecoration: "none",
    borderRadius: 50,
    border: "1px solid",
    borderColor: COLORS.primary,
  },
  textAlignLeft: {
    textAlign: "left",
    "@media(max-width:575px)": {
      textAlign: "center",
      marginBottom: 8,
    },
  },
  textAlignRight: {
    textAlign: "center",
    // "@media(max-width:575px)": {
    //   textAlign: "center",
    //   marginBottom: 16,
    // },
  },
});

const DownloadApp = () => {
  const { classes } = styles();
  return (
    <div className={classes.root}>
      <h2 className={classes.heading}>Download App Now</h2>
      <p className={classes.description}>
        Select 11 Application is available on google play store you can download
        app from play store as well as this website. Enjoy Stock!
      </p>
      <Box sx={{ marginTop: 30 }}>
        <Grid justify="center" align="center">
          <Grid.Col span={12} sm={6} xs={6} md={6} lg={6} xl={6}>
            <Box className={classes.textAlignRight}>
              <a
                href="https://play.google.com/store/apps/details?id=com.select11&hl=en&gl=US"
                target="_blank"
                className={classes.playStoreLink}
              >
                From PlayStore
              </a>
            </Box>
          </Grid.Col>
          {/* <Grid.Col span={12} sm={6} xs={6} md={6} lg={6} xl={6}>
            <Box className={classes.textAlignLeft}>
              <a href="/" className={classes.downloadLink}>
                Download NOW
              </a>
            </Box>
          </Grid.Col> */}
        </Grid>
      </Box>
    </div>
  );
};

export default DownloadApp;
