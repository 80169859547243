import logo from "./logo.png";
import bull from "./bull.jpg";
import googlePlayStore from "./google-play-store.png";
import splashScreen from "./splash-screen.png";
import drawerScreen from "./drawer.png";
import loginScreen from "./carousel/login.png";
import notificationScreen from "./carousel/notification.png";
import registerScreen from "./carousel/register.png";
import selectSCreen from "./carousel/select.png";
import viewStockSCreen from "./carousel/view-stock.png";
import winnerListSCreen from "./carousel/winner-list.png";
import withdrawSCreen from "./carousel/withdrae.png";
import withdrawHistoryScreen from "./carousel/withdraw-hiistory.png";
export const IMAGES = {
  logo,
  bull,
  googlePlayStore,
  splashScreen,
  drawerScreen,
  loginScreen,
  withdrawHistoryScreen,
  withdrawSCreen,
  winnerListSCreen,
  viewStockSCreen,
  selectSCreen,
  registerScreen,
  notificationScreen,
};
