import React, { useRef } from "react";
import { Carousel } from "@mantine/carousel";
import { createStyles, Box } from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";
import { IMAGES } from "../../image";
import { COLORS } from "../../color";

const styles = createStyles({
  root: {
    marginTop: "140px !important",
    width: "90%",
    margin: "auto",
    "@media(max-width:767px)": {
      width: "85%",
    },
  },
  image: {
    width: "80%",
  },
  heading: {
    fontSize: "clamp(16px, 3.5vw, 38px)",
    color: COLORS.primary,
    fontWeight: 600,
    margin: 0,
    textAlign: "center",
    paddingBottom: 50,
  },
});

const HomeCarousel = () => {
  const { classes } = styles();
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  return (
    <div className={classes.root}>
      <h2 className={classes.heading}>App Screens</h2>
      <Carousel
        slideSize="25%"
        slideGap="md"
        loop
        align="start"
        breakpoints={[
          { maxWidth: "md", slideSize: "50%" },
          { maxWidth: "sm", slideSize: "100%", slideGap: 0 },
        ]}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        withControls={false}
      >
        <Carousel.Slide>
          <Box>
            <img src={IMAGES.splashScreen} alt="" className={classes.image} />
          </Box>
        </Carousel.Slide>
        <Carousel.Slide>
          <img src={IMAGES.loginScreen} alt="" className={classes.image} />
        </Carousel.Slide>
        <Carousel.Slide>
          <img src={IMAGES.registerScreen} alt="" className={classes.image} />
        </Carousel.Slide>
        <Carousel.Slide>
          <img src={IMAGES.drawerScreen} alt="" className={classes.image} />
        </Carousel.Slide>
        <Carousel.Slide>
          <img src={IMAGES.selectSCreen} alt="" className={classes.image} />
        </Carousel.Slide>
        <Carousel.Slide>
          <img src={IMAGES.viewStockSCreen} alt="" className={classes.image} />
        </Carousel.Slide>
        <Carousel.Slide>
          <img src={IMAGES.winnerListSCreen} alt="" className={classes.image} />
        </Carousel.Slide>
        <Carousel.Slide>
          <img src={IMAGES.withdrawSCreen} alt="" className={classes.image} />
        </Carousel.Slide>
        <Carousel.Slide>
          <img
            src={IMAGES.withdrawHistoryScreen}
            alt=""
            className={classes.image}
          />
        </Carousel.Slide>
      </Carousel>
    </div>
  );
};

export default HomeCarousel;
