import { createStyles } from "@mantine/core";
import React from "react";
import { COLORS } from "../../color";

const styles = createStyles({
  root: {
    paddingBottom: 24,
    marginTop: 40,
  },
  para: {
    fontSize: 14,
    margin: 0,
    textAlign: "center",
    marginTop: 10,
    color: COLORS.black,
    fontWeight: 600,
  },
  link: {
    color: COLORS.primary,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
  },
});

const TheFooter = () => {
  const { classes } = styles();
  return (
    <div className={classes.root}>
      <div>
        <p className={classes.para}>
          © {new Date().getFullYear()} Stock 11 - All rights Reserved{" "}
        </p>
        <p className={classes.para}>
          Designed & Developed by{" "}
          <a href="https://meerasolution.com/" className={classes.link}>
            Meera Solution
          </a>
        </p>
      </div>
    </div>
  );
};

export default TheFooter;
