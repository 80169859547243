import { Box, createStyles } from "@mantine/core";
import React from "react";
import { COLORS } from "../../color";

const styles = createStyles({
  container: {
    maxWidth: 1140,
    margin: "auto",
    marginTop: "clamp(32px, 7.5vw, 128px)",
    width: "85%",
  },
  root: {
    padding: "32px clamp(12px, 2vw, 32px)",
    background: COLORS.white,
    borderRadius: 15,
    marginTop: "clamp(32px, 7.5vw, 40px)",
    marginBottom: "clamp(32px, 7.5vw, 40px)",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    ul: {
      paddingLeft: 20,
    },
    ol: {
      paddingLeft: 20,
    },
    li: {
      fontSize: "clamp(12px, 2.8vw, 20px)",
      fontWeight: 400,
      color: COLORS.black,
      lineHeight: "160%",
      marginTop: "clamp(12px, 2.8vw, 20px)",
    },
  },
  title: {
    fontSize: "clamp(18px, 3.5vw, 40px)",
  },
  description: {
    fontSize: "clamp(12px, 2.8vw, 20px)",
    fontWeight: 400,
    color: COLORS.black,
    lineHeight: "160%",
  },
});

const TermAndCondition = () => {
  const { classes } = styles();
  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <h1 className={classes.title}>Select 11</h1>
        <p className={classes.description}>
          Select 11 as used herein shall be construed as a collective reference
          to Select 11 and the Select 11 App.
        </p>
        <ul>
          <li>Usage of Select 11</li>
          <li>
            Any person ("User") accessing Select 11 or the Select 11 App
            ('Select 11 platform') for participating in the various contests,
            available on Select 11 platform ("Contest(s)") ('Select 11
            Services') shall be bound by these Terms and Conditions, and all
            other rules, regulations and terms of use referred to herein or
            provided by Select 11 in relation to any Select 11 Services.
          </li>
          <li>
            Select 11 shall be entitled to modify these Terms and Conditions,
            rules, regulations and terms of use referred to herein or provided
            by Select 11 in relation to any Select 11 Services, at any time, by
            posting the same on Select 11. Use of Select 11 constitutes the
            User's acceptance of such Terms and Conditions, rules, regulations
            and terms of use referred to herein or provided by Select 11 in
            relation to any Select 11 Services, as may be amended from time to
            time. Select 11 may, at its sole discretion, also notify the User of
            any change or modification in these Terms and Conditions, rules,
            regulations and terms of use referred to herein or provided by
            Select 11, by way of sending an email to the User's registered email
            address or posting notifications in the User accounts. The User may
            then exercise the options provided in such an email or notification
            to indicate non-acceptance of the modified Terms and Conditions,
            rules, regulations and terms of use referred to herein or provided
            by Select 11. If such options are not exercised by the User within
            the time frame prescribed in the email or notification, the User
            will be deemed to have accepted the modified Terms and Conditions,
            rules, regulations and terms of use referred to herein or provided
            by Select 11
          </li>
          <li>
            Certain Select 11 Services being provided on Select 11 may be
            subject to additional rules and regulations set down in that
            respect. To the extent that these Terms and Conditions are
            inconsistent with the additional conditions set down, the additional
            conditions shall prevail
          </li>
          <li>Select 11 may, at its sole and absolute discretion:</li>
          <li>
            Restrict, suspend, or terminate any User's access to all or any part
            of Select 11 or Select 11 Platform Services;
          </li>
          <li>
            Change, suspend, or discontinue all or any part of the Select 11
            Platform Services
          </li>
          <li>
            Reject, move, or remove any material that may be submitted by a
            User;
          </li>
          <li>
            Move or remove any content that is available on Select 11 Platform;
          </li>
          <li>
            Establish general practices and limits concerning use of Select 11
            Platform
          </li>
          <li>
            Assign its rights and liabilities to all User accounts hereunder to
            any entity (post such assignment intimation of such assignment shall
            be sent to all Users to their registered email ids)
          </li>
          <li>
            In the event any User breaches, or Select 11 reasonably believes
            that such User has breached these Terms and Conditions, or has
            illegally or improperly used Select 11 or the Select 11 Services,
            Select 11 may, at its sole and absolute discretion, and without any
            notice to the User, restrict, suspend or terminate such User's
            access to all or any part of Select 11 Contests or the Select 11
            Platform, deactivate or delete the User's account and all related
            information on the account, delete any content posted by the User on
            Select 11 and further, take technical and legal steps as it deems
            necessary
          </li>
          <li>
            If Select 11 charges its Users a platform fee in respect of any
            Select 11 Services, Select 11 shall, without delay, repay such
            platform fee in the event of suspension or removal of the User's
            account or Select 11 Services on account of any negligence or
            deficiency on the part of Select 11, but not if such suspension or
            removal is effected due to:
          </li>
          <li>
            any breach or inadequate performance by the User of any of these
            Terms and Conditions; or
          </li>
          <li>any circumstances beyond the reasonable control of Select 11.</li>
          <li>
            Users consent to receiving communications such as announcements,
            administrative messages and advertisements from Select 11 or any of
            its partners, licensors or associates.
          </li>
        </ul>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Intellectual Property</h1>
        <p className={classes.description}>
          Select 11 includes a combination of content created by Select 11, its
          partners, affiliates, licensors, associates and/or Users. The
          intellectual property rights ("Intellectual Property Rights") in all
          software underlying Select 11 and the Select 11 Platform and material
          published on Select 11, including (but not limited to) games,
          Contests, software, advertisements, written content, photographs,
          graphics, images, illustrations, marks, logos, audio or video
          clippings and Flash animation, is owned by Select 11, its partners,
          licensors and/or associates. Users may not modify, publish, transmit,
          participate in the transfer or sale of, reproduce, create derivative
          works of, distribute, publicly perform, publicly display, or in any
          way exploit any of the materials or content on Select 11 either in
          whole or in part without express written license from Select 11
        </p>
        <ul>
          <li>
            Users may request permission to use any Select 11 content by writing
            in to Select 11 Helpdesk.
          </li>
          <li>
            Users are solely responsible for all materials (whether publicly
            posted or privately transmitted) that they upload, post, e-mail,
            transmit, or otherwise make available on Select 11 ("Users'
            Content"). Each User represents and warrants that he/she owns all
            Intellectual Property Rights in the User's Content and that no part
            of the User's Content infringes any third party rights. Users
            further confirm and undertake to not display or use of the names,
            logos, marks, labels, trademarks, copyrights or intellectual and
            proprietary rights of any third party on Select 11. Users agree to
            indemnify and hold harmless Select 11, its directors, employees,
            affiliates and assigns against all costs, damages, loss and harm
            including towards litigation costs and counsel fees, in respect of
            any third party claims that may be initiated including for
            infringement of Intellectual Property Rights arising out of such
            display or use of the names, logos, marks, labels, trademarks,
            copyrights or intellectual and proprietary rights on Select 11, by
            such User or through the User's commissions or omissions
          </li>
          <li>Displaying Users' Content on Select 11</li>
          <li>
            Distributing Users' Content, either electronically or via other
            media, to other Users seeking to download or otherwise acquire it,
            and/or
          </li>
          <li>
            Storing Users' Content in a remote database accessible by end users,
            for a charge.
          </li>
          <li>
            This license shall apply to the distribution and the storage of
            Users' Content in any form, medium, or technology.
          </li>
          <li>
            All names, logos, marks, labels, trademarks, copyrights or
            intellectual and proprietary rights on Select 11(s) belonging to any
            person (including User), entity or third party are recognized as
            proprietary to the respective owners and any claims, controversy or
            issues against these names, logos, marks, labels, trademarks,
            copyrights or intellectual and proprietary rights must be directly
            addressed to the respective parties under notice to Select 11.
          </li>
        </ul>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}>
          Third Party Sites, Services and Products
        </h1>
        <p className={classes.description}>
          Select 11 may contain links to other Internet sites owned and operated
          by third parties. Users' use of each of those sites is subject to the
          conditions, if any, posted by the sites. Select 11 does not exercise
          control over any Internet sites apart from Select 11and cannot be held
          responsible for any content residing in any third-party Internet site.
          Select 11's inclusion of third-party content or links to third-party
          Internet sites is not an endorsement by Select 11 of such third-party
          Internet site.
        </p>
        <ul>
          <li>
            Users' correspondence, transactions/offers or related activities
            with third parties, including payment providers and verification
            service providers, are solely between the User and that third party.
            Users' correspondence, transactions and usage of the services/offers
            of such third party shall be subject to the terms and conditions,
            policies and other service terms adopted/implemented by such third
            party, and the User shall be solely responsible for reviewing the
            same prior to transacting or availing of the services/offers of such
            third party. User agrees that Select 11 will not be responsible or
            liable for any loss or damage of any sort incurred as a result of
            any such transactions/offers with third parties. Any questions,
            complaints, or claims related to any third party product or service
            should be directed to the appropriate vendor.
          </li>
          <li>
            Select 11 contains content that is created by Select 11 as well as
            content provided by third parties. Select 11 does not guarantee the
            accuracy, integrity, quality of the content provided by third
            parties and such content may not relied upon by the Users in
            utilizing the Select 11 Services provided on Select 11 including
            while participating in any of the contests hosted on Select 11.
          </li>
        </ul>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Privacy Policy</h1>
        <p className={classes.description}>
          All information collected from Users, such as registration and credit
          card information, is subject to Select 11's Privacy Policy which is
          available at Privacy Policy
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> User Conduct</h1>
        <p className={classes.description}>
          Users agree to abide by these Terms and Conditions and all other
          rules, regulations and terms of use of the Website. In the event User
          does not abide by these Terms and Conditions and all other rules,
          regulations and terms of use, Select 11 may, at its sole and absolute
          discretion, take necessary remedial action, including but not limited
          to:
        </p>
        <ul>
          <li>
            Restricting, suspending, or terminating any User's access to all or
            any part of Select 11 Services;
          </li>
          <li>
            Deactivating or deleting a User's account and all related
            information and files on the account. Any amount remaining unused in
            the User's Unutilised Account or Winnings Account (subject to
            deduction of applicable TDS with effect from 1st April 2023 as
            applicable) on the date of deactivation or deletion shall be
            transferred to the User's bank account on record with Select 11
            subject to a processing fee (if any) applicable on such transfers as
            set out herein;
          </li>
          <li>Refraining from awarding any prize(s) to such User</li>
          <li>
            Users agree to provide true, accurate, current and complete
            information at the time of registration and at all other times (as
            required by Select 11). Users further agree to update and keep
            updated their registration information
          </li>
          <li>
            Users agree and acknowledge that the address related details
            provided, if any, by the user shall be treated as “Address on
            record” of the user for the purposes of Goods and Service Tax Act.
            In the event of the change in such details, the user shall promptly
            update his/her profile on the Select 11 platform or write to Select
            11 at helpdesk
          </li>
          <li>
            A User shall not register or operate more than one User account with
            Select 11.
          </li>
          <li>
            A User shall not register or operate more than one User account with
            Select 11.
          </li>
          <li>
            Users agree to ensure that they can receive all communication from
            Select 11 by marking e-mails or sending SMSs from Select 11 as part
            of their "safe senders" list. Select 11 shall not be held liable if
            any e-mail/SMS remains unread by a User as a result of such e-mail
            getting delivered to the User's junk or spam folder.
          </li>
          <li>
            Users are responsible for maintaining the confidentiality of their
            accounts and passwords. Users agree to immediately notify Select 11
            of any unauthorized use of accounts or any other breach of security.
          </li>
          <li>
            Users agree to exit/log-out of their accounts at the end of each
            session. Select 11 shall not be responsible for any loss or damage
            that may result if the User fails to comply with these requirements
          </li>
          <li>
            Users agree not to use cheats, exploits, automation, software, bots,
            hacks or any unauthorised third party software designed to modify or
            interfere with Select 11 Services and/or Select 11 experience or
            assist in such activity.
          </li>
          <li>
            Users agree not to copy, modify, rent, lease, loan, sell, assign,
            distribute, reverse engineer, grant a security interest in, or
            otherwise transfer any right to the technology or software
            underlying Select 11 or Select 11’s Services.
          </li>
          <li>
            Users agree that without Select 11's express written consent, they
            shall not modify or cause to be modified any files or software that
            are part of Select 11's Services
          </li>
          <li>
            Users agree not to disrupt, overburden, or aid or assist in the
            disruption or overburdening of (a) any computer or server used to
            offer or support Select 11 or the Select 11’s Services (each a
            "Server"); or (2) the enjoyment of Select 11 Services by any other
            User or person.
          </li>
          <li>
            Users agree not to institute, assist or become involved in any type
            of attack, including without limitation to distribution of a virus,
            denial of service, or other attempts to disrupt Select 11 Services
            or any other person's use or enjoyment of Select 11 Services.
          </li>
          <li>
            Users shall not attempt to gain unauthorised access to the User
            accounts, Servers or networks connected to Select 11 Services by any
            means other than the User interface provided by Select 11, including
            but not limited to, by circumventing or modifying, attempting to
            circumvent or modify, or encouraging or assisting any other person
            to circumvent or modify, any security, technology, device, or
            software that underlies or is part of Select 11 Services.
          </li>
          <li>
            User shall not to publish any content that is patently false and
            untrue, and is written or published in any form, with the intent to
            mislead or harass a person, entity or agency for financial gain or
            to cause any injury to any person.
          </li>
          <li>
            Without limiting the foregoing, Users agree not to use Select 11 for
            any of the following:
          </li>
          <li>
            To engage in any obscene, offensive, indecent, racial, communal,
            anti-national, objectionable, defamatory or abusive action or
            communication;
          </li>
          <li>
            To harass, stalk, threaten, or otherwise violate any legal rights of
            other individuals;
          </li>
          <li>
            To publish, post, upload, e-mail, distribute, or disseminate
            (collectively, "Transmit") any inappropriate, profane, defamatory,
            infringing, obscene, indecent, or unlawful content;
          </li>
          <li>
            To Transmit files that contain viruses, corrupted files, or any
            other similar software or programs that may damage or adversely
            affect the operation of another person's computer, Select 11, any
            software, hardware, or telecommunications equipment;
          </li>
          <li>
            To advertise, offer or sell any goods or services for any commercial
            purpose on Select 11 without the express written consent of Select
            11
          </li>
          <li>
            To download any file, recompile or disassemble or otherwise affect
            our products that you know or reasonably should know cannot be
            legally obtained in such manner;
          </li>
          <li>
            To falsify or delete any author attributions, legal or other proper
            notices or proprietary designations or labels of the origin or the
            source of software or other material;
          </li>
          <li>
            To restrict or inhibit any other user from using and enjoying any
            public area within our sites;
          </li>
          <li>To collect or store personal information about other Users;</li>
          <li>To interfere with or disrupt Select 11, servers, or networks;</li>
          <li>
            To impersonate any person or entity, including, but not limited to,
            a representative of Select 11, or falsely state or otherwise
            misrepresent User's affiliation with a person or entity;
          </li>
          <li>
            To forge headers or manipulate identifiers or other data in order to
            disguise the origin of any content transmitted through Select 11 or
            to manipulate User's presence on Select 11(s);
          </li>
          <li>
            To forge headers or manipulate identifiers or other data in order to
            disguise the origin of any content transmitted through Select 11 or
            to manipulate User's presence on Select 11(s);
          </li>
          <li>
            To take any action that imposes an unreasonably or
            disproportionately large load on our infrastructure;
          </li>
          <li>
            To engage in any illegal activities. You agree to use our bulletin
            board services, chat areas, news groups, forums, communities and/or
            message or communication facilities (collectively, the "Forums")
            only to send and receive messages and material that are proper and
            related to that particular Forum.
          </li>
          <li>
            To engage in any action that threatens the unity, integrity,
            defence, security or sovereignty of India, friendly relations with
            foreign States, or public order, or causes incitement to the
            commission of any cognisable offence or prevents investigation of
            any offence or is insulting other nation.
          </li>
          <li>
            If a User chooses a username that, in Select 11's considered opinion
            is obscene, indecent, abusive or that might subject Select 11 to
            public disparagement or scorn, or a name which is an official
            team/league/franchise names and/or name of any sporting personality,
            as the case may be, Select 11 reserves the right, without prior
            notice to the User, to restrict usage of such names, which in Select
            11’s opinion fall within any of the said categories and/or change
            such username and intimate the User or delete such username and
            posts from Select 11, deny such User access to Select 11, or any
            combination of these options.
          </li>
          <li>
            Unauthorized access to Select 11 is a breach of these Terms and
            Conditions, and a violation of the law. Users agree not to access
            Select 11 by any means other than through the interface that is
            provided by Select 11 for use in accessing Select 11. Users agree
            not to use any automated means, including, without limitation,
            agents, robots, scripts, or spiders, to access, monitor, or copy any
            part of our sites, except those automated means that we have
            approved in advance and in writing.
          </li>
          <li>
            Use of Select 11 is subject to existing laws and legal processes.
            Nothing contained in these Terms and Conditions shall limit Select
            11's right to comply with governmental, court, and law-enforcement
            requests or requirements relating to Users' use of Select 11.
          </li>
          <li>Users may reach out to Select 11 through -</li>
          <li>
            Helpdesk if the user has any concerns with regard to a match and/or
            contest within Forty Eight (48) hours of winner declaration for the
            concerned contest.
          </li>
          <li>
            Persons below the age of eighteen (18) years are not allowed to
            participate on any of the contests (by whatever name called) on the
            Select 11 Platform. The Users will have to disclose their real age
            at the time of getting access into the Select 11 Platform.
          </li>
          <li>
            Select 11 may not be held responsible for any content contributed by
            Users on the Select 11.
          </li>
          <li>
            User hereby confirms that he / she is participating in a contest in
            personal capacity and not in the course of business and /or
            profession.
          </li>
        </ul>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Conditions of Participation:</h1>
        <p className={classes.description}>
          By entering a Contest, user agrees to be bound by these Terms and the
          decisions of Select 11. Subject to the terms and conditions stipulated
          herein below, the Select 11, at its sole discretion, may disqualify
          any user from a Contest, refuse to award benefits or prizes and
          require the return of any prizes, if the user engages in unfair
          conduct, which the Select 11 deems to be improper, unfair or otherwise
          adverse to the operation of the Contest or is in any way detrimental
          to other Users which includes, but is not limited to:
        </p>
        <ul>
          <li>
            Falsifying ones’ own personal information including, but not limited
            to, name, email address, bank account details and/or any other
            information or documentation as may be requested by Select 11 to
            enter a contest and/or claim a prize/winning.;
          </li>
          <li>
            Engaging in any type of financial fraud or misrepresentation
            including unauthorized use of credit/debit instruments, payment
            wallet accounts etc. to enter a Contest or claim a prize. It is
            expressly clarified that the onus to prove otherwise shall solely
            lie on the user.;
          </li>
          <li>
            Colluding with any other user(s) or engaging in any type of
            syndicate play;
          </li>
          <li>Any violation of Contest rules or the Terms of Use;</li>
          <li>
            Accumulating points or prizes through unauthorized methods such as
            automated bots, or other automated means;
          </li>
          <li>
            Using automated means (including but not limited to harvesting bots,
            robots, parser, spiders or screen scrapers) to obtain, collect or
            access any information on the Website or of any User for any purpose
          </li>
          <li>
            Any type of Cash Bonus misuse, misuse of the Invite Friends program,
            or misuse of any other offers or promotions;
          </li>
          <li>
            Tampering with the administration of a Contest or trying to in any
            way tamper with the computer programs or any security measure
            associated with a Contest;
          </li>
          <li>
            Obtaining other users’ information without their express consent
            and/or knowledge and/or spamming other users (Spamming may include
            but shall not be limited to send unsolicited emails to users,
            sending bulk emails to Select 11 Users, sending unwarranted email
            content either to selected Users or in bulk);
          </li>
          <li>
            Abusing the Website in any way (‘unparliamentary language, slangs or
            disrespectful words’ are some of the examples of Abuse) It is
            clarified that in case a User is found to be in violation of this
            policy, Select 11 reserves its right to initiate appropriate
            Civil/Criminal remedies as it may be advised other than forfeiture
            and/or recovery of prize money if any.
          </li>
        </ul>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Registration for a contest</h1>
        <p className={classes.description}>
          In order to register for the Contest(s), Participants are required to
          accurately provide the following information:
        </p>
        <ul>
          <li>
            <b>Full Name</b>
          </li>
          <li>
            <b>Team Name(s)</b>
          </li>
          <li>
            <b>E-mail address</b>
          </li>
          <li>
            <b>Password</b>
          </li>
          <li>
            <b>State of Residence</b>
          </li>
          <li>
            <b>Gender</b>
          </li>
          <li>
            <b>Date of birth</b>
          </li>
          <li>
            Participants are also required to confirm that they have read, and
            shall abide by, these Terms and Conditions.
          </li>
          <li>
            In the event a Participant indicates, while entering an address,
            that he/she is a resident of either Andhra Pradesh, Assam, Nagaland,
            Odisha, Sikkim or Telangana such Participant will not be permitted
            to proceed to sign up for any match in the paid version of the
            Contest as described below.
          </li>
          <li>
            Once the Participants have entered the above information, and
            clicked on the "register" tab, and such Participants are above the
            age of 18 years, they are sent an email confirming their
            registration and containing their login information.
          </li>
        </ul>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}>Contest(s), Participation and Prizes</h1>
        <p className={classes.description}>
          As part of its services, Select 11 may make available the contest(s)
          on the Select 11 platform.
        </p>
        <ul>
          <li>
            Currently, following contests are made available on Select 11 Nifty
            100 Participants can participate in such Contest(s) with their
            Teams.
          </li>
          <li>
            Depending upon the circumstances of each contest , the participants
            can edit their teams till the official start time as declared by
            Select 11, as specified below.
          </li>
          <li>
            Select 11 reserves the right to abandon a specific round or adjust
            the deadline of a round in certain specific, uncertain scenarios,
            which are beyond Select 11’s reasonable control, including but not
            limited to the ones’ mentioned herein below:
          </li>
        </ul>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Public contest</h1>
        <p className={classes.description}>
          In the Public contest format of the Contest(s), Select 11 may make
          available the Contest(s) comprising of 2 -– 100 Participants or any
          other pre-designated number of Participants.
        </p>
        <ul>
          <li>
            Select 11 may create this format of the Contest(s) as a paid format
            and the Winner will be determinable at the end of the match as per
            rule of the contests.
          </li>
          <li>
            The number of Participants required to make the Contest(s)
            operational will be pre-specified and once the number of
            Participants in such Contest(s) equals the pre-specified number
            required for that Contest(s), such Contest(s) shall be operational.
            In case the number of Participants is less than the pre-specified
            number at the time of commencement of the match, such Contest(s)
            will not be operational and the pre-designated amount paid by each
            Participant shall be returned to the account of such User without
            any charge or deduction.
          </li>
          <li>
            In certain Contests across the Select 11 Services, designated as
            “Guaranteed contests”, the Contest(s) shall become operational only
            when a minimum of two users join a Guaranteed Contest. The
            pre-specified number of winners to be declared in such Contest(s),
            even if all available Participant slots (as pre-specified in
            relation to the Contest(s)) remain unfilled. It is clarified that
            notwithstanding the activation of such Contest(s), Participants can
            continue to join such Contest(s) till either (i) all available
            Participant slots of such Contest(s) are filled or (ii) the match to
            which the Contest (s) relates commences, whichever is earlier. In
            the event of shortfall in the number of participants joining the
            Guaranteed Contest, Select 11 shall continue with such contests and
            the short fall in the prize pool shall be borne by Select 11.
          </li>
        </ul>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Legality of Game of Skill</h1>
        <p className={classes.description}>
          Games of skill are legal, as they are excluded from the ambit of
          Indian gambling legislations including, the Public Gambling Act of
          1867.The Indian Supreme Court in the cases of State of Andhra Pradesh
          v. K Satyanarayana (AIR 1968 SC 825) and KR Lakshmanan v. State of
          Tamil Nadu (AIR 1996 SC 1153) has held that a game in which success
          depends predominantly upon the superior knowledge, training,
          attention, experience and adroitness of the player shall be classified
          as a game of skill.
        </p>
        <ul>
          <li>
            The Contest (s) described above (across the Select 11 Services) are
            games of skill as success of Participants depends primarily on their
            superior knowledge of the Stock market, knowledge of stocks'
            relative form, stock' performance in a particular Day, conditions
            and/or format of news, attention and dedication towards the
            Contest(s) and adroitness in playing the Contest(s).
          </li>
          <li>
            By participating in this Contest(s), each Participant acknowledges
            and agrees that he/she is participating in a game of skill.
          </li>
        </ul>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}>Eligibility</h1>
        <ul>
          <li>
            The Contest(s) are open only to persons above the age of 18 years.
          </li>
          <li>
            The Contest(s) are open only to persons, currently residing in
            India.
          </li>
          <li>
            Select 11 may, in accordance with the laws prevailing in certain
            Indian states, bar individuals residing in those states from
            participating in the Contest(s). Currently, individuals residing in
            the Indian states of Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim
            or Telangana may not participate in the paid version of the Contest
            as the laws of these states are unclear/ bar persons from
            participating in games of skill where participants are required to
            pay to enter.
          </li>
          <li>
            Persons who wish to participate must have a valid email address.
          </li>
          <li>
            Select 11 may on receipt of information bar a person from
            participation and/or withdrawing winning amounts if such person is
            found to be one with insider knowledge of participating teams in any
            given contests/match, organizing boards, leagues etc.
          </li>
          <li>
            Only those Participants who have successfully registered on the
            Select 11 as well as registered prior to each match in accordance
            with the procedure outlined above shall be eligible to participate
            in the Contest and win prizes.
          </li>
        </ul>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Payment Terms</h1>
        <p className={classes.description}>
          Users by participating in a Contest(s) hereby authorize Select 11 to
          appoint a third party/ Trustee/Escrow Agent to manage users funds on
          users behalf. In respect of any transactions entered into on the
          Select 11 platform, including making a payment to participate in the
          paid versions of Contest(s), Users agree to be bound by the following
          payment terms:
        </p>
        <ul>
          <li>
            The payment of pre-designated amount Users make to participate in
            the Contest(s) is inclusive of the pre-designated platform fee
            (inclusive of applicable GST) for access to the Select 11 Services
            charged by Select 11 and pre-determined participant’s contribution
            towards prize money pool.
          </li>
          <li>
            Subject to these Terms and Conditions, all amounts collected from
            the User are held in a separate non-interest earning bank Accounts.
            The said accounts are operated by a third party appointed by Select
            11 in accordance with Clause 10 of these Terms and Conditions. From
            these bank accounts, the payouts can be made to (a) Users (towards
            their withdrawals), (b) Select 11 (towards its Platform Fees) and to
            (c) Government (towards TDS on Net Winnings). Select 11 receives
            only its share of the platform Fees through the said Escrow Agent.
          </li>
          <li>
            The Select 11 reserves the right to charge a Platform Fee, prior to
            a User's joining of such Contest. The Platform Fee (inclusive of
            applicable Goods and Service Tax (“GST”) thereon) will be debited
            from the User’s account balance and Select 11 shall issue an invoice
            for such debit to the User. User can view the GST paid on each paid
            contest join through invoices on ‘My Transaction’ page. The GST
            value listed on the users invoice shall be the final GST amount
            charged to the user.
          </li>
          <li>
            The User may participate in a Contest wherein the User has to
            contribute a pre-specified contribution towards the Prize Money Pool
            of such Contest, which will be passed on to the Winner(s) of the
            Contest after the completion of the Contest as per the terms and
            conditions of such Contest. It is clarified that Select 11 has no
            right or interest in this Prize Money Pool, and only acts as an
            intermediary engaged in collecting and distributing the Prize Money
            Pool in accordance with the Contest terms and conditions. The amount
            to be paid-in by the User towards the Prize Money Pool would also be
            debited from the User’s account balance maintained with the Trustee.
          </li>
          <li>
            Any user availing Select 11 services are provided with two
            categories of accounts for the processing and reconciliation of
            payments: (i) 'Unutilized' Account, (ii) Winnings Account. It is
            clarified that in no instance the transfer of any amounts in the
            User's accounts to any other category of account held by the user or
            any third party account, including a bank account held by a third
            party:
          </li>
          <li>
            User's winnings in any Contest will reflect as credits to the User's
            Winnings Account.
          </li>
          <li>
            User’s remitting the amount through the designated payment gateway
            shall be credited to User’s Unutlized Account’.
          </li>
        </ul>
      </Box>
      <Box className={classes.root}>
        <p className={classes.description}>
          Each time a User participates in any contest on Select 11 platform,
          the pre-designated amount shall be debited in the User’s account. In
          debiting amounts from the User’s accounts towards the pre-designated
          amount of such user shall be debited from the User’s Unutilized
          Account and thereafter, any remaining amount of participation fee
          shall be debited from the User’s Winning Account.
        </p>
        <ol>
          <li>
            In case there is any amount remaining to be paid by the User in
            relation to such User’s participation in any match(s) or Contest(s),
            the User will be taken to the designated payment gateway to give
            effect to such payment. In case any amount added by the User through
            such payment gateway exceeds the remaining amount of the
            pre-designated amount, the amount in excess shall be transferred to
            the User’s ‘Unutilized’ Account and will be available for use in
            participation in any match(s) or Contest(s).
          </li>
          <li>
            Debits from the ‘Unutilized’ Account for the purpose of enabling a
            user’s participation in a Contest shall be made in order of the date
            of credit of amounts in the ‘Unutilized’ Account, and accordingly
            amounts credited into ‘Unutilized’ Account earlier in time shall be
            debited first.
          </li>
          <li>
            Withdrawal of any amount standing to the User's credit in the
            Winnings Account may be made by way of a request to Select 11.
            Select 11 shall effect an online transfer to the User's bank account
            on record with Select 11 within a commercially reasonable period of
            time. Such transfer will reflect as a debit to the User's Winnings
            Account. Select 11 shall not charge any processing fee for the
            online transfer of such amount from the Winnings Account to the
            User's bank account on record with Select 11. Users are requested to
            note that they will be required to provide valid photo
            identification, Pan Card and address proof documents for proof of
            identity and address in order for Select 11 to process the
            withdrawal request. The name mentioned on the User's photo
            identification document should correspond with the name provided by
            the User at the time of registration on Select 11, as well as the
            name and address existing in the records of the User's bank account
            as provided to Select 11. In the event that no bank account has been
            registered by the User against such User's account with Select 11,
            or the User has not verified his/her User account with Select 11, to
            Select 11's satisfaction and in accordance with these Terms and
            Conditions, and in case the User fails to register a bank account
            with his/her User Account and/or to verify his/her User Account,
            Select 11 shall not transfer any Winning amounts to the User.
          </li>
          <li>
            Further, in order to conduct promotional activities, Select 11 may
            gratuitously issue Cash Bonus to the User for the purpose of
            participation in any Contest(s) and no User shall be permitted to
            transfer or request the transfer of any amount in to the Cash Bonus.
            The usage of any Cash Bonus issued shall be subject to the
            limitations and restrictions, including without limitation,
            restrictions as to time within which such Cash Bonus must be used,
            as applied by Select 11 and notified to the User at the time of
            issue of such amount. The issue of any Cash Bonus to the user is
            subject to the sole discretion of Select 11 and cannot be demanded
            by any User as a matter of right. The issue of any Cash Bonus by
            Select 11 on any day shall not entitle the user to demand the
            issuance of such Cash Bonus at any subsequent period in time nor
            create an expectation of recurring issue of such Cash Bonus by
            Select 11 to such User. The Cash Bonus granted to the user may be
            used by such User for the purpose of setting off against the
            contribution to prize pool in any Contest, in accordance with these
            Terms and Conditions. The Cash Bonus shall not be withdraw-able or
            transferrable to any other account of the User, including the bank
            account of such User, or of any other User or person, other that as
            part of the winnings of a User in any Contest(s). In case the User
            terminates his/her account with Select 11 or such account if
            terminated by Select 11, all Cash Bonus granted to the user shall
            return to Select 11 and the User shall not have any right or
            interest on such Cash Bonus.
          </li>
          <li>
            All Cash Bonus credited in the User account shall be valid for a
            period of 14 days from the date of credit. The unutilized Cash Bonus
            shall expire at the end of 14 days from the date of credit.
          </li>
          <li>
            Users agree that once they confirm a transaction on Select 11, they
            shall be bound by and make payment for that transaction.
          </li>
          <li>
            The User acknowledges that subject to time taken for bank
            reconciliations and such other external dependencies that Select 11
            has on third parties, any transactions on Select 11 Platform may
            take up to 24 hours to be processed. Any amount paid or transferred
            into the User's 'Unutilized' Account or Winnings Account may take up
            to 24 hours to reflect in the User's 'Unutilized' Account or
            Winnings Account balance. Similarly, the utilization of the Cash
            Bonus or money debited from 'Unutilized' Account or Winnings Account
            may take up to 24 hours to reflect in the User's 'Unutilized'
            Account or Winnings Account balance. Users agree not to raise any
            complaint or claim against Select 11 in respect of any delay,
            including any lost opportunity to join any Contest or match due to
            delay in crediting of transaction amount into any of the User's
            accounts A transaction, once confirmed, is final and no cancellation
            is permissible. Select 11 may, in certain exceptional circumstances
            and at its sole and absolute discretion, refund the amount to the
            User after deducting applicable cancellation charges and taxes. At
            the time of the transaction, Users may also be required to take note
            of certain additional terms and conditions and such additional terms
            and conditions shall also govern the transaction. To the extent that
            the additional terms and conditions contain any clause that is
            conflicting with the present terms and conditions, the additional
            terms and conditions shall prevail.
          </li>
          <li>
            Selection and Verification of Winners and Conditions relating to the
            Prizes
          </li>

          <li>
            <b>Selection of Winners :-</b>
            <br />
            Winners will be decided on the basis of percentage with the highest
            aggregate profit percentage in a particular contest shall be
            declared the Winner(s). In certain pre-specified Contests, Select 11
            may declare more than one Winner and distribute prizes to such
            Winners in increasing order of their Team's aggregate percantage at
            the end of the designated contestt. The contemplated number of
            Winners and the prize due to each Winner in such Contest shall be as
            specified on the Contest page prior to the commencement of the
            Contest. <br />
            Participants creating Teams on behalf of any other Participant or
            person shall be disqualified.
            <br />
            In the event of a tie, the winning Participants shall be declared
            Winners and the prize shall be equally divided among such
            Participants. <br />
            Select 11 shall not be liable to pay any prize if it is discovered
            that the Winner(s) have not abided by these Terms and Conditions,
            and other rules and regulations in relation to the use of the Select
            11, Contest, ".
          </li>
          <li>
            <b>Contacting Winners</b>
            <br />
            Winners shall be contacted by Select 11 or the third party
            conducting the Contest on the e-mail address provided at the time of
            registration. The verification process and the documents required
            for the collection of prize shall be detailed to the Winners at this
            stage. As a general practice, winners will be required to provide
            following documents:
          </li>
          <li> Photocopy of the User's PAN card;</li>
          <li>Photocopy of a government-issued residence proof;</li>
          <li>User's bank account details and proof of the same.</li>
        </ol>
        <p className={classes.description}>
          Select 11 shall not permit a Winner to withdraw his/her
          prize(s)/accumulated winnings unless the above-mentioned documents
          have been received and verified within the time-period stipulated by
          Select 11. The User represents and warrants that the documents
          provided in the course of the verification process are true copies of
          the original documents to which they relate.
        </p>
        <p className={classes.description}>
          Participants are required to provide proper and complete details at
          the time of registration. Select 11 shall not be responsible for
          communications errors, commissions or omissions including those of the
          Participants due to which the results may not be communicated to the
          Winner.
        </p>
        <p className={classes.description}>
          The list of Winners shall be posted on a separate web-page on the
          Select 11. The winners will also be intimated by e-mail.
        </p>
        <p className={classes.description}>
          In the event that a Participant has been declared a Winner on the
          abovementioned web-page but has not received any communication from
          Select 11, such Participant may contact Select 11 within the time
          specified on the webpage.
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}>Verification process</h1>
        <p className={classes.description}>
          Only those Winners who successfully complete the verification process
          and provide the required documents within the time limit specified by
          Select 11 shall be permitted to withdraw/receive their accumulated
          winnings (or any part thereof). Select 11 shall not entertain any
          claims or requests for extension of time for submission of documents.
          Select 11 shall scrutinize all documents submitted and may, at its
          sole and absolute discretion, disqualify any Winner from withdrawing
          his accumulated winnings (or any part thereof) on the following
          reasons:
        </p>
        <ol>
          <li>
            Determination by Select 11 that any document or information
            submitted by the Participant is incorrect, misleading, false,
            fabricated, incomplete or illegible; or
          </li>
          <li>
            Participant does not fulfill the Eligibility Criteria specified in
            Clause 10 above; or
          </li>
          <li>Any other match.</li>
          <li>
            <b>Taxes Payable</b>
          </li>
          <li>
            All prizes shall be subject to deduction of tax (“TDS”) as per the
            Income Tax Act 1961. With effect from 1st April 2023, TDS of 30%
            shall be deducted on Net Winnings (NW) on each withdrawal request
            placed by the user.
          </li>
          <li>
            Net Winnings shall be calculated as follows: NW = (A+D)-(B+C+E).
            Where : A= Total withdrawal (including current withdrawal amount),
            B= Total Deposit, C= Opening Balance at the beginning of financial
            year, D= Closing balance of the user account at the end of financial
            year, E= Amount on which TDS was deducted. In the event, a user does
            not withdraw any of his winnings during a financial year and has Net
            Winning balance as at 31 March of each year, then TDS of 30% shall
            be deducted on such Net Winnings balance from the user’s Winning
            Account as on 31 March. In case of any revisions by the Government
            of India to the aforementioned rate or the definition of Net
            Winnings in the future, TDS will be deducted by Select 11 in
            accordance with the then current prescribed TDS rate and the revised
            definition of Net Winnings. Winners will be provided TDS
            certificates in respect of such tax deductions. The Winners shall be
            responsible for payment of any other applicable taxes, including but
            not limited to, income tax, gift tax, etc. in respect of the Net
            Winnings. Select 11 shall not in any manner be responsible for users
            individual tax matters.
          </li>
        </ol>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Miscellaneous</h1>
        <p className={classes.description}>
          The decision of Select 11 with respect to the awarding of prizes shall
          be final, binding and non-contestable.
        </p>
        <p className={classes.description}>
          Participants playing the paid formats of the Contest(s) confirm that
          they are not residents of any of the following Indian states - Andhra
          Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana. If it is found
          that a Participant playing the paid formats of the Contest(s) is a
          resident of any of the abovementioned states, Select 11 shall
          disqualify such Participant and forfeit any prize won by such
          Participant. Further Select 11 may, at its sole and absolute
          discretion, suspend or terminate such Participant's account with
          Select 11. Any amount remaining unused in the User's Unutilised
          Account or Winnings Account ( subject to deduction of applicable TDS
          with effect from 1st April 2023 as applicable ) on the date of
          deactivation or deletion shall be reimbursed to the User by an online
          transfer to the User's bank account on record with Select 11, subject
          to the processing fee (if any) applicable on such transfers as set out
          herein.
        </p>
        <p className={classes.description}>
          If it is found that a Participant playing the paid formats of the
          Contest(s) is under the age of eighteen (18), Select 11 shall be
          entitled, at its sole and absolute discretion, to disqualify such
          Participant and forfeit his/her prize. Further, Select 11 may, at its
          sole and absolute discretion, suspend or terminate such Participant's
          account.
        </p>

        <p className={classes.description}>
          To the extent permitted by law, Select 11 makes no representations or
          warranties as to the quality, suitability or merchantability of any
          prizes and shall not be liable in respect of the same.
        </p>
        <p className={classes.description}>
          Select 11 may, at its sole and absolute discretion, vary or modify the
          prizes being offered to winners. Participants shall not raise any
          claim against Select 11 or question its right to modify such prizes
          being offered, prior to closure of the Contest.
        </p>
        <p className={classes.description}>
          Select 11 will not bear any responsibility for the transportation or
          packaging of prizes to the respective winners. Select 11 shall not be
          held liable for any loss or damage caused to any prizes at the time of
          such transportation.
        </p>
        <p className={classes.description}>
          The Winners shall bear the shipping, courier or any other delivery
          cost in respect of the prizes.
        </p>
        <p className={classes.description}>
          The Winners shall bear all transaction charges levied for delivery of
          cash prizes.
        </p>
        <p className={classes.description}>
          All prizes are non-transferable and non-refundable. Prizes cannot be
          exchanged / redeemed for cash or kind. No cash claims can be made in
          lieu of prizes in kind.
        </p>
        <ol>
          <li>
            <b>Publicity</b>
            <br />
            Acceptance of a prize by the Winner constitutes permission for
            Select 11, and its affiliates to use the Winner's name, likeness,
            voice and comments for advertising and promotional purposes in any
            media worldwide for purposes of advertising and trade without any
            further permissions or consents and / or additional compensation
            whatsoever. The Winners further undertake that they will be
            available for promotional purposes as planned and desired by Select
            11 without any charge. The exact dates remain the sole discretion of
            Select 11. Promotional activities may include but not be limited to
            press events, internal meetings and ceremonies/functions.
          </li>
        </ol>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> General Conditions</h1>
        <p className={classes.description}>
          If it comes to the notice of Select 11 that any governmental,
          statutory or regulatory compliances or approvals are required for
          conducting any Contest(s) or if it comes to the notice of Select 11
          that conduct of any such Contest(s) is prohibited, then Select 11
          shall withdraw and / or cancel such Contest(s) without prior notice to
          any Participants or winners of any Contest(s). Users agree not to make
          any claim in respect of such cancellation or withdrawal of the Contest
          or Contest it in any manner.
        </p>
        <p className={classes.description}>
          Employees are not be eligible to participate in any Public Contest(s).
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}>Dispute and Dispute Resolution</h1>
        <ol>
          <li>
            The courts of competent jurisdiction at Waidhan shall have exclusive
            jurisdiction to determine any and all disputes arising out of, or in
            connection with, the Select 11 Services provided by Select 11
            (including the Contest(s)), the construction, validity,
            interpretation and enforceability of these Terms and Conditions, or
            the rights and obligations of the User(s) (including Participants)
            or Select 11, as well as the exclusive jurisdiction to grant interim
            or preliminary relief in case of any dispute referred to arbitration
            as given below. All such issues and questions shall be governed and
            construed in accordance with the laws of the Republic of India.
          </li>
          <li>
            In the event of any legal dispute (which may be a legal issue or
            question) which may arise, the party raising the dispute shall
            provide a written notification ("Notification") to the other party.
            On receipt of Notification, the parties shall first try to resolve
            the dispute through discussions. In the event that the parties are
            unable to resolve the dispute within fifteen (15) days of receipt of
            Notification, the dispute shall be settled by arbitration.
          </li>
          <li>
            The place of arbitration shall be Mumbai, India. All arbitration
            proceedings shall be conducted in English and in accordance with the
            provisions of the Arbitration and Conciliation Act, 1996, as amended
            from time to time.
          </li>
          <li>
            The arbitration award will be final and binding on the Parties, and
            each Party will bear its own costs of arbitration and equally share
            the fees of the arbitrator unless the arbitral tribunal decides
            otherwise. The arbitrator shall be entitled to pass interim orders
            and awards, including the orders for specific performance and such
            orders would be enforceable in competent courts. The arbitrator
            shall give a reasoned award.
          </li>
          <li>
            Nothing contained in these Terms and Conditions shall prevent Select
            11 from seeking and obtaining interim or permanent equitable or
            injunctive relief, or any other relief available to safeguard Select
            11's interest prior to, during or following the filing of
            arbitration proceedings or pending the execution of a decision or
            award in connection with any arbitration proceedings from any court
            having jurisdiction to grant the same. The pursuit of equitable or
            injunctive relief shall not constitute a waiver on the part of
            Select 11 to pursue any remedy for monetary damages through the
            arbitration described herein.
          </li>
          <li>Release and Limitations of Liability</li>
          <li>
            Users shall access the Select 11 Services provided on Select 11
            voluntarily and at their own risk. Select 11 shall, under no
            circumstances be held responsible or liable on account of any loss
            or damage sustained (including but not limited to any accident,
            injury, death, loss of property) by Users or any other person or
            entity during the course of access to the Select 11 Services
            (including participation in the Contest(s)) or as a result of
            acceptance of any prize.
          </li>
          <li>
            By entering the contests and accessing the Select 11 Services
            provided therein, Users hereby release from and agree to indemnify
            Select 11, and/ or any of its directors, employees, partners,
            associates and licensors, from and against all liability, cost, loss
            or expense arising out their access to the Select 11 Services
            including (but not limited to) personal injury and damage to
            property and whether direct, indirect, consequential, foreseeable,
            due to some negligent act or omission on their part, or otherwise.
          </li>

          <li>
            Select 11 accepts no liability, whether jointly or severally, for
            any errors or omissions, whether on behalf of itself or third
            parties in relation to the prizes.
          </li>
          <li>
            Users shall be solely responsible for any consequences which may
            arise due to their access of Select 11 Services by conducting an
            illegal act or due to non-conformity with these Terms and Conditions
            and other rules and regulations in relation to Select 11 Services,
            including provision of incorrect address or other personal details.
            Users also undertake to indemnify Select 11 and their respective
            officers, directors, employees and agents on the happening of such
            an event (including without limitation cost of attorney, legal
            charges etc.) on full indemnity basis for any loss/damage suffered
            by Select 11 on account of such act on the part of the Users.
          </li>
          <li>
            Users shall indemnify, defend, and hold Select 11 harmless from any
            third party/entity/organization claims arising from or related to
            such User's engagement with the Select 11 or participation in any
            Contest. In no event shall Select 11 be liable to any User for acts
            or omissions arising out of or related to User's engagement with the
            Select 11 or his/her participation in any Contest(s).
          </li>
          <li>
            In consideration of Select 11 allowing Users to access the Select 11
            Services, to the maximum extent permitted by law, the Users waive
            and release each and every right or claim, all actions, causes of
            actions (present or future) each of them has or may have against
            Select 11, its respective agents, directors, officers, business
            associates, group companies, sponsors, employees, or representatives
            for all and any injuries, accidents, or mishaps (whether known or
            unknown) or (whether anticipated or unanticipated) arising out of
            the provision of Select 11 Services or related to the Contests or
            the prizes of the Contests.
          </li>
        </ol>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Disclaimers</h1>
        <ol>
          <li>
            To the extent permitted under law, neither Select 11 nor its
            parent/holding company, subsidiaries, affiliates, directors,
            officers, professional advisors, employees shall be responsible for
            the deletion, the failure to store, the mis-delivery, or the
            untimely delivery of any information or material.
          </li>
          <li>
            To the extent permitted under law, Select 11 shall not be
            responsible for any harm resulting from downloading or accessing any
            information or material, the quality of servers, games, products,
            Select 11 services or sites, cancellation of competition and prizes.
            Select 11 disclaims any responsibility for, and if a User pays for
            access to one of Select 11's Services the User will not be entitled
            to a refund as a result of, any inaccessibility that is caused by
            Select 11's maintenance on the servers or the technology that
            underlies our sites, failures of Select 11's service providers
            (including telecommunications, hosting, and power providers),
            computer viruses, natural disasters or other destruction or damage
            of our facilities, acts of nature, war, civil disturbance, or any
            other cause beyond our reasonable control. In addition, Select 11
            does not provide any warranty as to the content on the Select 11(s).
            Select 11(s) content is distributed on an "as is, as available"
            basis.
          </li>
          <li>
            Any material accessed, downloaded or otherwise obtained through
            Select 11 is done at the User's discretion, competence, acceptance
            and risk, and the User will be solely responsible for any potential
            damage to User's computer system or loss of data that results from a
            User's download of any such material
          </li>
          <li>
            Select 11 shall make best endeavours to ensure that the Select 11(s)
            is error-free and secure, however, neither Select 11 nor any of its
            partners, licensors or associates makes any warranty that:
          </li>
          <li>the Select 11(s) will meet Users' requirements,</li>
          <li>
            Select 11(s) will be uninterrupted, timely, secure, or error free
          </li>
          <li>
            the results that may be obtained from the use of Select 11(s) will
            be accurate or reliable; and
          </li>
          <li>
            the quality of any products, Select 11 Services, information, or
            other material that Users purchase or obtain through Select 11com(s)
            will meet Users' expectations.
          </li>

          <li>
            In case Select 11 discovers any error, including any error in the
            determination of Winners or in the transfer of amounts to a User's
            account, Select 11 reserves the right (exercisable at its
            discretion) to rectify the error in such manner as it deems fit,
            including through a set-off of the erroneous payment from amounts
            due to the User or deduction from the User's account of the amount
            of erroneous payment. In case of exercise of remedies in accordance
            with this clause, Select 11 agrees to notify the User of the error
            and of the exercise of the remedy(ies) to rectify the same.
          </li>
          <li>
            To the extent permitted under law, neither Select 11 nor its
            partners, licensors or associates shall be liable for any direct,
            indirect, incidental, special, or consequential damages arising out
            of the use of or inability to use our sites, even if we have been
            advised of the possibility of such damages.
          </li>
          <li>
            Any Select 11 Services, events or Contest(s) being hosted or
            provided, or intended to be hosted on Select 11 platform and
            requiring specific permission or authority from any statutory
            authority or any state or the central government, or the board of
            directors shall be deemed cancelled or terminated, if such
            permission or authority is either not obtained or denied either
            before or after the availability of the relevant Select 11 Services,
            events or Contest(s) are hosted or provided.
          </li>
          <li>
            To the extent permitted under law, in the event of suspension or
            closure of any Services, events or Contests, Users (including
            Participants) shall not be entitled to make any demands, claims, on
            any nature whatsoever
          </li>
        </ol>
      </Box>
    </Box>
  );
};

export default TermAndCondition;
