import { Box, createStyles } from "@mantine/core";
import React from "react";
import HomeCarousel from "./home/HomeCarousel";
import DownloadApp from "./home/DownloadApp";
import HomeBanner from "./home/HomeBanner";
import GridContainer from "../components/grid-container/GridContainer";

const styles = createStyles({
  root: {
    width: "95%",
    margin: "auto",
    padding: "40px 0px",
    "@media(max-width:767px)": {
      width: "85%",
    },
  },
});

const Home = () => {
  const { classes } = styles();
  return (
    <Box className={classes.root}>
      <HomeBanner />
      <GridContainer
        firstImage="splashScreen"
        secondImage="drawerScreen"
        paragraph="  Select 11 is India’s first Equity Fantasy game. It is designed
                for all stock market enthusiasts and for people who want to step
                into the world of trading. Select 11 gives you a chance to test
                and enhance your knowledge of Equity, create virtual Portfolios
                and stand a chance to win exciting prizes daily. the player has
                to create a team of 11 stocks from list of Stocks ."
        title=" ABOUT US"
      />
      <GridContainer
        firstImage="viewStockSCreen"
        secondImage="winnerListSCreen"
        paragraph="   It's easy to start playing on Select 11 Ever thought of taking
                plunge into stock market but afraid of losing money? Try our
                Challenges for FREE. Build your teams and track your ranking
                throughout the day! Compete with thousands of Equity Market
                Enthusiasts just like you!"
        title=" HOW TO PLAY"
        link="/how-to-play"
        linkTitle=" Read More"
        firstOrder={1}
        secondOrder={2}
      />
      <HomeCarousel />
      <DownloadApp />
    </Box>
  );
};

export default Home;
