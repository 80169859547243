import React from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { appRoutes } from "./router/appRoutes";

function App() {
  return (
    <>
      <RouterProvider router={appRoutes} />
    </>
  );
}

export default App;
