import { Box, createStyles } from "@mantine/core";
import React from "react";
import { COLORS } from "../../color";

const styles = createStyles({
  container: {
    maxWidth: 1140,
    margin: "auto",
    marginTop: "clamp(32px, 7.5vw, 128px)",
    width: "85%",
  },
  root: {
    padding: "32px clamp(12px, 2vw, 32px)",
    background: COLORS.white,
    borderRadius: 15,
    marginTop: "clamp(32px, 7.5vw, 40px)",
    marginBottom: "clamp(32px, 7.5vw, 40px)",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    ul: {
      paddingLeft: 20,
    },
    ol: {
      paddingLeft: 20,
    },
    li: {
      fontSize: "clamp(12px, 2.8vw, 20px)",
      fontWeight: 400,
      color: COLORS.black,
      lineHeight: "160%",
      marginTop: "clamp(12px, 2.8vw, 20px)",
    },
  },
  title: {
    fontSize: "clamp(18px, 3.5vw, 40px)",
  },
  description: {
    fontSize: "clamp(12px, 2.8vw, 20px)",
    fontWeight: 400,
    color: COLORS.black,
    lineHeight: "160%",
  },
});

const PrivacyPolicy = () => {
  const { classes } = styles();
  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <h1 className={classes.title}>Privacy Policy</h1>
        <p className={classes.description}>
          Your privacy on the Internet is very important to us. We are truly
          committed to maintain the confidentiality of your personal information
          to allow you to enjoy playing our games. We safeguard your information
          and utilize the best in class software and hardware techniques and
          procedures.
        </p>
        <p className={classes.description}>
          The purpose of this section is to help you understand how we collect,
          use, and safeguard, share your information (in some situations) and
          explain your rights in relation to that information. When you use our
          gaming service, you consent to the collection and use of your personal
          identifying information as outlined in the Privacy Policy. Our policy
          may be amended from time to time and you agree to review our Privacy
          Policy periodically to become aware of any changes.
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> The Information We Collect</h1>
        <p className={classes.description}>
          We may collect and use the information, mentioned below, from you:
        </p>
        <ul>
          <li>
            Personal information, content, materials and information when you
            register for an account.
          </li>
          <li>Your profile that includes name, address and e-Mail.</li>
          <li>Your profile or avatar photo.</li>
          <li>
            Details of your interaction with the Service which includes traffic
            data, web logs, location data and other.
          </li>
        </ul>
        <p className={classes.description}>
          We will use your personal information to solve any technical
          difficulties and confirm financial transactions (if applicable), send
          you information on events and news and provide customer support
          services. We may collect additional information from you in order to
          improve our gaming services such as IP addresses, referring and
          redirect pages, browser information, browser history, platform type,
          clicks, etc. We are not responsible for the privacy policy of third
          party sites that we link to. This Privacy Policy solely applies to
          select11.co.in
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}>How We Use Information</h1>
        <p className={classes.description}>
          We clearly state that we do not sell your personal information to
          other third party sites. We may provide your information to government
          officials and agencies when required by law. We can also provide your
          data to any merchants, payment processors or operators who are in the
          business operations and transaction processing. We reserve the right
          to transfer your information in the event of a transfer of ownership
          select11 or the acquisition of substantially all the assets related to
          the business.
        </p>
        <p className={classes.description}>
          We could also use your information to notify you of new contest
          releases, services and to solicit your feedback and input.
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Child Protection</h1>
        <p className={classes.description}>
          Select11.co.in does not knowingly collect or use personal information
          from any player under the age of 18. If you are under the age of 18
          years, please do not register or send any of your information to us.
          We will permanently delete any information from players who are below
          18 years of age.
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Managing Your Personal Information</h1>
        <p className={classes.description}>
          We aim to keep our information about you accurately. You can terminate
          your account any time and we will remove your profile and other
          information from public view. May (but is not obligated to) store
          information about you. Please contact Select 11 App Support or Contact
          to terminate your account and to get answers for any questions about
          your personal information.
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Cookies</h1>
        <p className={classes.description}>
          We may send you a temporary cookie when you visit our site or use our
          financial services. A cookie is a small file of text-only string of
          information that can be embedded in your computer so that we can
          remember who you are when you revisit our site. We may use the data
          generated from cookies to compile data on your use at our Application.
          You are not obliged to accept our cookie and you can decline cookies
          by modifying the settings in your Phone or Device.
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Changes TO Our Privacy Policy</h1>
        <p className={classes.description}>
          We may amend this statement at any time and with immediate effect by
          updating this page of Privacy Policy. We will seek to notify you of
          any changes beforehand. If we are not able to do so, it is your
          responsibility to periodically review any changes in this Privacy
          Policy page.
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}>Your Consent</h1>
        <p className={classes.description}>
          By registering on our App, you give your express consent for the
          collection and use of your personal information by select11 its
          partners and affiliates. From time to time, we may change our methods
          of collecting information and modify our Privacy Policy. We will post
          such changes on this page so that you are always aware of what
          information we collect, how we use it, and under what circumstances we
          disclose it. Make sure that you stay updated on our Privacy Policy
          revisions.
        </p>
        <p className={classes.description}>
          Select11 assets that this Privacy Policy is only a description of its
          operation regarding user information. This Privacy Policy does not
          create any legal rights in your favour or in favour of any other
          person, group, or organization. Select11 reserves the right to change
          this Privacy Policy at any time without prior notice.
        </p>
        <p className={classes.description}>
          In the course of providing the Services, Users may invite other
          existing Users or other users ("Invited Users") to participate in any
          of the Services by providing the Contact’s details, including phone
          number and/or email addresses, of such users. Select11 may thereafter
          use this information to contact the Invited User and invite such user
          to register with select11 (if such Invited User is not an existing
          User) and participate in the Contest in relation to which such person
          was invited by the User. The participation of the Invited User in any
          of the Contest shall be subject to the terms of this Privacy Policy
          and the Terms and Conditions for the use of the Portal. The User
          hereby represents that the Invited Users have consented and agreed to
          such disclosure to and use of their Contact’s Information by select11
        </p>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
