import { Box, createStyles } from "@mantine/core";
import React from "react";
import { COLORS } from "../../color";

const styles = createStyles({
  container: {
    maxWidth: 1140,
    margin: "auto",
    marginTop: "clamp(32px, 7.5vw, 100px)",
    width: "85%",
  },
  root: {
    padding: "32px clamp(12px, 2vw, 32px)",
    background: COLORS.white,
    borderRadius: 15,
    marginTop: "clamp(32px, 7.5vw, 40px)",
    marginBottom: "clamp(32px, 7.5vw, 40px)",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
  },
  title: {
    fontSize: "clamp(18px, 3.5vw, 40px)",
  },
  description: {
    fontSize: "clamp(12px, 2.8vw, 20px)",
    fontWeight: 400,
    color: COLORS.black,
    lineHeight: "160%",
  },
});

const HowToPlay = () => {
  const { classes } = styles();
  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <h1 className={classes.title}> How 2 play</h1>
        <p className={classes.description}>
          It's easy to start playing on Select 11 Ever thought of taking plunge
          into stock market but afraid of losing money? Try our Challenges for
          FREE. Build your teams and track your ranking throughout the day!
          Compete with thousands of Equity Market Enthusiasts just like you!
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Introduction</h1>
        <p className={classes.description}>
          Select 11 is India’s first Equity Fantasy game. It is designed for all
          stock market enthusiasts and for people who want to step into the
          world of trading. Select 11 gives you a chance to test and enhance
          your knowledge of Equity, create virtual Portfolios and stand a chance
          to win exciting prizes daily. the player has to create a team of 11
          stocks from list of Stocks .
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Create your team</h1>
        <p className={classes.description}>
          <b> STEP 1:</b> SELECT THE TEAM To create your team, You will have to
          select 11 stocks from the list. If one is bullish about the stock and
          believes that the price of the stock will increase on the day of
          challenge, one should select the stock. If one is bearish about the
          stock and believes that the price of the stock will decrease on the
          day of challenge, one should leave the stock. If you have selected a
          stock and you want to change it, you can chance before the contest
          start
        </p>
        <p className={classes.description}>
          <b>STEP 2:</b> JOIN A CHALLENGE Joining the challenge depends on your
          Users. Users can join any Challenge available while user can join
          limited challenge(s).Click on 'Join Now' and It's Done.
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Manage Your Team</h1>
        <p className={classes.description}>
          We have a daily game during Market hours. One can join the match as
          below mentioned schedule.
        </p>
        <p className={classes.description}>
          Day Time of Registration for the Game
        </p>
        <p className={classes.description}>
          <b>Monday Previous Friday 09:00:01 AM to Monday 8:59:59 AM</b>
        </p>
        <p className={classes.description}>
          <b>Tuesday Monday 09:00:01 AM to Tuesday 8:59:59 AM</b>
        </p>
        <p className={classes.description}>
          <b>Wednesday Tuesday 09:00:01 AM to Wednesday 8:59:59 AM</b>
        </p>
        <p className={classes.description}>
          <b>Thursday Wednesday 09:00:01 AM to Thursday 8:59:59 AM</b>
        </p>
        <p className={classes.description}>
          <b>Friday Thursday 09:00:01 AM to Friday 8:59:59 AM</b>
        </p>
        <p className={classes.description}>
          One can make changes to the team as many times as one wishes before
          the Challenge starts at 9 AM for the day. To edit the team, go to the
          ‘Upcoming Matches’ on Dashboard and tap on ‘Edit’. One cannot make
          change in 'Challenges'.
        </p>
      </Box>
      <Box className={classes.root}>
        <h1 className={classes.title}> Point System</h1>
        <p className={classes.description}>
          One has to select 11 stocks from the list of Stocks. These stocks
          carry equal weightage in the points system. The points shown is
          achieved by addition of %change(+/-). For example, if any stock has
          gained 2%, the points will be considered for the stock is 2 . All the
          points are added. All the %change information will be taken from
          exchange. The final score is calculated by adding points(+/-) of all
          the individual stocks. Results will be announced for the day before
          3.45 PM.The teams will be ranked on the basis of final score. If there
          is a tie between multiple teams, then the money will be shared equally
          by taking the average of their winning amounts.
        </p>
      </Box>
    </Box>
  );
};

export default HowToPlay;
