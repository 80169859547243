import React from "react";
import { Box, Grid, createStyles } from "@mantine/core";
import { COLORS } from "../../color";
import { IMAGES } from "../../image";
import Typewriter from "typewriter-effect";

const styles = createStyles({
  title: {
    fontSize: 90,
    fontWeight: 700,
    textTransform: "uppercase",
    margin: 0,
    color: COLORS.black,
    "@media(max-width:992px)": {
      fontSize: 60,
    },
    "@media(max-width:400px)": {
      fontSize: 40,
    },
  },
  para: {
    lineHeight: "160%",
    fontSize: "clamp(12px, 2.8vw, 20px)",
    fontWeight: 400,
  },
  playStoreImage: {
    width: "100%",
    borderRadius: 5,
  },
  playStoreContainer: {
    background: COLORS.black,
    padding: "7px 30px",
    borderRadius: 50,
    height: 43,
    marginRight: 10,
    "@media(max-width:600px)": {
      marginRight: 0,
      marginBottom: 20,
    },
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  stockImage: {
    width: "80%",
    "@media(max-width:767px)": {
      width: "100%",
    },
  },
  downloadLink: {
    padding: "10px 30px",
    background: COLORS.white,
    color: COLORS.primary,
    fontWeight: 500,
    textDecoration: "none",
    borderRadius: 50,
    border: "1px solid",
    borderColor: COLORS.primary,
    height: 43,
  },
  row: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "@media(max-width:600px)": {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
});

const HomeBanner = () => {
  const { classes } = styles();
  return (
    <Grid justify="center" align="center">
      <Grid.Col
        span={12}
        sm={6}
        xs={12}
        md={6}
        lg={6}
        xl={6}
        orderXs={2}
        orderSm={1}
        order={2}
      >
        <Box className={classes.flexContainer}>
          <h2 className={classes.title} style={{ paddingRight: 22 }}>
            Live{" "}
          </h2>
          <h2 className={classes.title}>
            <Typewriter
              onInit={(typewriter) => {
                typewriter.pauseFor(500).deleteAll().start();
              }}
              options={{
                strings: [" KHELO", " jeeto"],
                autoStart: true,
                loop: true,
                cursorClassName: classes.title,
              }}
            />
          </h2>
        </Box>

        <p className={classes.para}>Download the Select 11 Mobile App</p>
        <Box style={{ marginTop: 30 }}>
          <Box className={classes.row}>
            <Box className={classes.playStoreContainer}>
              <a
                href="https://play.google.com/store/apps/details?id=com.select11&hl=en&gl=US"
                target="_blank"
              >
                <img
                  src={IMAGES.googlePlayStore}
                  alt="googlePlayStore"
                  className={classes.playStoreImage}
                  style={{ height: "100%" }}
                />
              </a>
            </Box>
            {/* <Box>
              <a href="/" className={classes.downloadLink}>
                Download NOW
              </a>
            </Box> */}
          </Box>
        </Box>
      </Grid.Col>
      <Grid.Col
        span={12}
        sm={6}
        xs={12}
        md={6}
        lg={6}
        xl={6}
        orderXs={1}
        orderSm={2}
        order={1}
      >
        <Box style={{ textAlign: "right" }}>
          <img src={IMAGES.bull} alt="" className={classes.stockImage} />
        </Box>
      </Grid.Col>
    </Grid>
  );
};

export default HomeBanner;
