import React from "react";
import HomeBanner from "../home/HomeBanner";
import { Box, Grid, createStyles } from "@mantine/core";
import { IMAGES } from "../../image";
import { COLORS } from "../../color";

const styles = createStyles({
  root: {
    width: "95%",
    margin: "auto",
    padding: "40px 0px",
    "@media(max-width:767px)": {
      width: "85%",
    },
  },
  heading: {
    fontSize: "clamp(16px, 3.5vw, 38px)",
    color: COLORS.primary,
    fontWeight: 600,
    margin: 0,
    textTransform: "uppercase",
  },
  description: {
    fontSize: "clamp(12px, 2.8vw, 20px)",
    fontWeight: 400,
    color: COLORS.black,
    lineHeight: "160%",
    opacity: 0.8,
  },
  link: {
    color: COLORS.primary,
    textDecoration: "none",
    fontWeight: 500,
  },
});

const ContactUs = () => {
  const { classes } = styles();
  return (
    <Box className={classes.root}>
      <HomeBanner />
      <Box sx={{ marginTop: 140 }}>
        <Grid justify="center" align="center">
          <Grid.Col
            span={12}
            sm={6}
            xs={12}
            md={6}
            lg={6}
            xl={6}
            orderXs={2}
            orderSm={1}
            order={2}
          >
            <Grid justify="center" align="center">
              <Grid.Col span={6}>
                <Box style={{ textAlign: "center" }}>
                  <img src={IMAGES.splashScreen} alt="" width={"70%"} />
                </Box>
              </Grid.Col>
              <Grid.Col span={6}>
                <Box style={{ textAlign: "left" }}>
                  <img src={IMAGES.drawerScreen} alt="" width={"70%"} />
                </Box>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col
            span={12}
            sm={6}
            xs={12}
            md={6}
            lg={6}
            xl={6}
            orderXs={1}
            orderSm={2}
            order={1}
          >
            <Box style={{ marginBottom: 30 }}>
              <h2 className={classes.heading} style={{ paddingRight: 22 }}>
                contact US
              </h2>
              <p className={classes.description}>
                For any contact and support you can reach us through our mobile
                application select 11 Android app.
              </p>
              <p className={classes.description}>
                For deletion of your account or any relavent query kindly reach
                us through the support.
              </p>
              <p className={classes.description}>
                For future reference or application disconnecting issue mail us
                at{" "}
                <a href="mailto:info@select11.co.in" className={classes.link}>
                  info@select11.co.in{" "}
                </a>
                or write us at{" "}
                <a
                  href="mailto:contact@select11.co.in"
                  className={classes.link}
                >
                  contact@select11.co.in
                </a>{" "}
                or get support via{" "}
                <a
                  href="mailto:support@select11.co.in"
                  className={classes.link}
                >
                  support@select11.co.in
                </a>
              </p>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContactUs;
